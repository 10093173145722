/* Provide sufficient contrast against white background */
body {
    font-family: 'Titillium Web', 'Helvetica Neue', Arial, sans-serif;
    background: rgb(255,255,255);
    color: #262626;
    font-size: 16px;
}

header {
    padding-bottom: 30px;
}

.title-page-divider {
    background-color: #CF2554;
    height: 7px;
    width: 185px;
    margin: 20px auto;
}

.title-page-divider-secondary {
    background-color: #CF2554;
    height: 7px;
    width: 185px;
    margin: 20px auto;
}


a {
    color:#CF2554;
}

a:not([href]) {
    color:#CF2554;
    cursor:pointer;
}

a:not([href]):hover {
    color:#CF2554;
    cursor: pointer;
}

.bm-item-list a:not([href]) {
    color: #ffffff;
    cursor: pointer;
}

.bm-item-list a:not([href]):hover {
    color: #ffffff;
    cursor: pointer;
}

a:hover {
    color: #CF2554;
}

.btn {
    padding: 0.35rem 1.5rem;
}

.btn-primary {
  color: #fff;
  background-color:#CF2554;
  border-color:#CF2554;
  font-size:18px;
}

.btn-secondary {
    color: #575A5E;
    background-color: #F2F2F2;
    border-color: #575A5E;
    font-size:16px;
    font-weight:bold;
}

.text-primary {
    color: #575A5E;
}

.text-blue {
    color: #051E38;
}

.text-blue-secondary {
    color: #051E38;
}

.text-grey {
    color: #575A5E;
}

.text-grey-secondary {
    color: #575A5E;
}


.text-strong { 
    font-weight: bold !important;
}

.social-buttons {
    text-align:center;
}

.social-buttons a:hover {
    text-decoration: none;
}

.social-buttons a {
    font-size: 18px;
    color: #CF2554;
    margin:0 10px;
    font-weight:bold;
}

.social-buttons a:hover {
    font-size: 18px;
    color: #CF2554;
    margin:0 10px;
    font-weight:bold;
}

.button {
    background-color: #F2F2F2;
    border-color: #F2F2F2;
    color:#CF2554;
}

h1, .h1 {
    font-size: 42px !important; 
    font-weight: 200 !important;
    color:#051e38 !important;
    text-align:center !important;
}

h2, .h2 {
    font-size: 30px;
    font-weight: 400;
    color: #575A5E;
}

h3, .h3 {
    font-size: 24px;
    font-weight: 700;
    color:#CF2554;
}

h4, .h4 {
    color: #CF2554;
    font-weight:700;
}

h6, .h6 {
    font-size: 24px; 
}

.news-list h3 {
    font-weight: 700;
    color:#051E38;
    margin-bottom: 0px;
}

.news-list h3 a {
    font-weight: 700;
    color:#051E38;
}

.news-list h3 a:hover {
    font-weight: 700;
    color:#051E38;
}

.news-list p {
    margin-bottom: 0px;
}

.bottomSection {
    margin-top: 30px;
    padding: 30px 0px;
    font-size: 16px;
    font-weight: normal;
    background-color: #051e38;
    color: #fff;
}

.bottomSection h1, .bottomSection h2 {
    font-weight: 200;
    color: #fff;
}

.bottomSection h3 {
    font-weight: 400;
    color: #fff;
}

.bottomSection p {
    font-size: 16px;
}

.btn {
    border-radius: 0px;
}

.btn-default {
    font-size: 16px;
    color: #051e38;
    background-color: #F2F2F2;
    border: solid 1px #051e38;
}

.btn-empty {
    padding:0 !important;
    font-size:20px;
}

.margin-t-3 {
    margin-top: 3em;
}

.margin-t-1 {
    margin-top: 1em;
}

.margin-b-1 {
    margin-bottom: 1em;
}

.margin-b-3 {
    margin-bottom: 3em;
}


.modulesaccordion .card,
.modulesaccordion .card:last-child .card-header {
    background-color: transparent;
}

.modulesaccordion .card {
    font-size: 16px;
    color: #051e38;
    border: none;
}

.modulesaccordion .card-header {
    border-bottom-color: #051e38;
    background-color: transparent;
    font-size: 24px;
    font-weight: bold;
    color: #051e38;
    cursor: pointer;
}

.modulesaccordion .card-header:after {
    font-family: 'FontAwesome';
    content: "\f078";
    float: right;
}

.modulesaccordion .card-header.collapsed:after {
    /* symbol for "collapsed" panels */
    content: "\f077";
}

.modulesaccordion div.collapse.show
{
    border-top: #CF2554 solid 1px;
    margin-top: -1px;
}

.modulesaccordion li + li {
    margin-top: 10px;
}

/* calendar */

.datetime-calendar-small, .datetime-calendar-small table {
    font-size: 90% !important;
    text-align: center;
}

.datetime-calendar-large, .datetime-calendar-large table {
    font-size: 140% !important;
    text-align: center;
}

table.calendar-day{
    font-size: 24px;
    text-align: center;
    display: inline;
}

table.calendar-day th {
    padding: 20px 15px;
}

table.calendar-day td {
    padding: 12px 15px;
}

td.calendar-day {
    border: 1px solid #575A5E;
}

td.empty{
    border:none;
}

td.sunday {
    background-color: #003166;
    color: #fff;
}

td.sunday {
    background-color: #003166;
    color: #fff;
}

td.today {
    background-color: #FFD51A;
    color: #575A5E;
}

@media (min-width: 1200px) {
}

@media (max-width: 1200px) and (min-width:922px) {
}

@media (max-width: 992px) and (min-width:768px) {

    .xs-text-center {
        text-align: center !important;
    }
}

@media (max-width: 768px) and (min-width:576px) {

    .xs-text-center {
        text-align: center !important;
    }
    
}

@media (max-width: 576px) {

    .xs-text-center {
        text-align: center !important;
    }
    
    .bm-menu-wrap{
        top: 0 !important;
    }
}